import { ResponsiveProp } from '../responsiveProps'

import { responsiveToCss as css } from './utils'

export function transition(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css('transition', value, important)
}

export function transform(value?: ResponsiveProp<string>, important?: boolean) {
  return css('transform', value, important)
}
