import { createContext, useContext } from 'react'

import type { Dimension } from 'src/types'

type ColumnsContextValue = {
  collapseOnMobile: boolean
  collapseOnTablet: boolean
  reverseOnCollapse?: boolean
  mobileSpace: Dimension
  tabletSpace: Dimension
  desktopSpace: Dimension
}

export const ColumnsContext = createContext<ColumnsContextValue>({
  collapseOnMobile: false,
  collapseOnTablet: false,
  reverseOnCollapse: false,
  mobileSpace: 'none',
  tabletSpace: 'none',
  desktopSpace: 'none'
})

export const useColumnsContext = () => useContext(ColumnsContext)
