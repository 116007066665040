import {
  getIsOnStandardBackground,
  useBackground,
} from "src/context/Background";
import { filledProps, getFilledButtonProps, } from "src/hooks/button";
import { useTheme, } from "src/themes";
import colorWithOpacity from "src/utils/colorWithOpacity";

import { IconButtonTone, } from "../consts";

type Args = { tone: IconButtonTone; disabled?: boolean };

export default function useGetIconButtonStyles({
  tone: toneProp,
  disabled,
}: Args) {
  const theme = useTheme();
  const background = useBackground();
  const isOnStandardBackground = getIsOnStandardBackground(background);

  const tone = toneProp || "primary";

  if (tone === "blend") {
    if (isOnStandardBackground) {
      return {
        ...staticStyleProps,
        ...filledProps,
        color: theme.contrast,
        backgroundColor: "transparent",
        hoverStyles: {
          backgroundColor: colorWithOpacity(theme.contrast, 0.4),
        },
        activeStyles: {
          backgroundColor: colorWithOpacity(theme.contrast, 0.8),
        },
      };
    }

    return {
      ...staticStyleProps,
      ...filledProps,
      color: theme.gray["600"],
      backgroundColor: "transparent",
      hoverStyles: {
        backgroundColor: colorWithOpacity(theme.gray["100"], 0.4),
      },
      activeStyles: {
        backgroundColor: theme.gray["100"],
      },
    };
  } else {
    return {
      ...staticStyleProps,
      ...getFilledButtonProps({ theme, background, tone, disabled }),
    };
  }
}

const staticStyleProps = {
  display: "block",
  borderRadius: "xxlarge",
  textDecoration: "none",
  fontWeight: "bold",
  cursor: "pointer",
  className: "focus-mineShaft",
  outline: "none",
};
