import * as React from 'react';

import { ResponsiveDimension, ResponsiveProp } from 'src/types';
import { normaliseResponsiveProp } from 'src/utils/responsiveProps';

import Box from '../Box';

type Align = 'start' | 'end' | 'center'

/*
padding, margin and className props have been  deprecated
stacks job is to create even space between its children and these props
change that functionality such as creating space between a stack and 
a parent or changing background colour
at some point in the future hopefully we'll be able to remove them
but for they will be supported, we'll just get flow warnings for
incorrect usage
*/

type Props = {
  children: React.ReactNode
  space?: ResponsiveDimension
  align?: ResponsiveProp<Align>
}

const Stack = React.forwardRef<Props, any>(
  (
    {
      children,
      className,
      space,
      align = 'start',
      margin,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      marginY,
      marginX,
      padding,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingY,
      paddingX
    },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className={className}
        display='flex'
        flexDirection='column'
        width='100%'
        margin={margin}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginY={marginY}
        marginX={marginX}
        padding={padding}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingY={paddingY}
        paddingX={paddingX}
      >
        {React.Children.toArray(children)
          .filter((child) => child !== null && child !== undefined)
          .map((child, index) => (
            <Box
              key={index}
              {...getChildStyle({
                space,
                index,
                align
              })}
              width='100%'
            >
              {child}
            </Box>
          ))}
      </Box>
    )
  }
)

type Args = {
  align?: ResponsiveProp<Align>
  space?: ResponsiveDimension
  index: number
}

function getChildStyle({ space, align, index }: Args) {
  const normalisedAlign = normaliseResponsiveProp(align)
  const { display, justifyContent } = normalisedAlign.reduce<{
    display: string[]
    justifyContent: string[]
  }>(
    (reduction, align) => {
      return {
        display: [...reduction.display, resolveDisplay(align)],
        justifyContent: [
          ...reduction.justifyContent,
          resolveJustifyContent(align)
        ]
      }
    },
    { display: [], justifyContent: [] }
  )

  if (index === 0) {
    return { display, justifyContent }
  }

  return { marginTop: space, display, justifyContent }
}

function resolveDisplay(align?: Align) {
  if (align === 'end') {
    return 'flex'
  }

  if (align === 'center') {
    return 'flex'
  }

  return 'block'
}

function resolveJustifyContent(align?: Align) {
  if (align === 'end') {
    return 'flex-end'
  }

  if (align === 'center') {
    return 'center'
  }

  return 'flex-start'
}

export default Stack
