import { ResponsiveProp } from "../responsiveProps";

import { responsiveToCss as css } from "./utils";

import type {
  BoxSizing,
  Display,
  JustifyContent,
  AlignItems,
  FlexDirection,
  FlexWrap,
  Width,
  Height,
  Position,
  FontWeight,
  TextAlign,
  Overflow,
  WhiteSpace,
  TextOverflow,
  TextTransform,
} from "../types";

export function background(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("background", value, important);
}

export function boxSizing(
  value?: ResponsiveProp<BoxSizing>,
  important?: boolean
) {
  return css("box-sizing", value, important);
}

export function display(value?: ResponsiveProp<Display>, important?: boolean) {
  return css("display", value, important);
}

export function justifyContent(
  value?: ResponsiveProp<JustifyContent>,
  important?: boolean
) {
  return css("justify-content", value, important);
}

export function justifySelf(
  value?: ResponsiveProp<JustifyContent>,
  important?: boolean
) {
  return css("justify-self", value, important);
}

export function justifyItems(
  value?: ResponsiveProp<JustifyContent>,
  important?: boolean
) {
  return css("justify-items", value, important);
}

export function alignSelf(
  value?: ResponsiveProp<JustifyContent>,
  important?: boolean
) {
  return css("align-self", value, important);
}

export function alignItems(
  value?: ResponsiveProp<AlignItems>,
  important?: boolean
) {
  return css("align-items", value, important);
}

export function flexDirection(
  value?: ResponsiveProp<FlexDirection>,
  important?: boolean
) {
  return css("flex-direction", value, important);
}

export function flex(value?: ResponsiveProp<string>, important?: boolean) {
  return css("flex", value, important);
}

export function flexWrap(
  value?: ResponsiveProp<FlexWrap>,
  important?: boolean
) {
  return css("flex-wrap", value, important);
}

export function width(value?: ResponsiveProp<Width>, important?: boolean) {
  return css("width", value, important);
}
export function maxWidth(value?: ResponsiveProp<Width>, important?: boolean) {
  return css("max-width", value, important);
}

export function minWidth(value?: ResponsiveProp<Width>, important?: boolean) {
  return css("min-width", value, important);
}
export function height(value?: ResponsiveProp<Height>, important?: boolean) {
  return css("height", value, important);
}
export function maxHeight(value?: ResponsiveProp<Height>, important?: boolean) {
  return css("max-height", value, important);
}

export function minHeight(value?: ResponsiveProp<Height>, important?: boolean) {
  return css("min-height", value, important);
}

export function cursor(value?: string, important?: boolean) {
  return css("cursor", value, important);
}

export function pointerEvents(value?: string, important?: boolean) {
  return css("pointer-events", value, important);
}
export function position(
  value?: ResponsiveProp<Position>,
  important?: boolean
) {
  return css("position", value, important);
}

export function top(value?: ResponsiveProp<string>, important?: boolean) {
  return css("top", value, important);
}

export function bottom(value?: ResponsiveProp<string>, important?: boolean) {
  return css("bottom", value, important);
}

export function right(value?: ResponsiveProp<string>, important?: boolean) {
  return css("right", value, important);
}

export function left(value?: ResponsiveProp<string>, important?: boolean) {
  return css("left", value, important);
}

export function backgroundColor(value?: string, important?: boolean) {
  return css("background-color", value, important);
}

export function color(value?: string, important?: boolean) {
  return css("color", value, important);
}

export function border(value?: string, important?: boolean) {
  return css("border", value, important);
}

export function borderColor(value?: string, important?: boolean) {
  return css("border-color", value, important);
}

export function borderStyle(value?: string, important?: boolean) {
  return css("border-style", value, important);
}

export function borderRightStyle(value?: string, important?: boolean) {
  return css("border-right-style", value, important);
}

export function borderBottomStyle(value?: string, important?: boolean) {
  return css("border-bottom-style", value, important);
}

export function boxShadow(value?: string, important?: boolean) {
  return css("box-shadow", value, important);
}

export function fill(value?: string, important?: boolean) {
  return css("fill", value, important);
}

export function stroke(value?: string, important?: boolean) {
  return css("stroke", value, important);
}

export function outline(value?: string, important?: boolean) {
  return css("outline", value, important);
}

export function gridTemplateAreas(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("grid-template-areas", value, important);
}

export function gridTemplateColumns(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("grid-template-columns", value, important);
}

export function gridArea(value?: ResponsiveProp<string>, important?: boolean) {
  return css("grid-area", value, important);
}

export function textAlign(
  value?: ResponsiveProp<TextAlign>,
  important?: boolean
) {
  return css("text-align", value, important);
}

export function textDecoration(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("text-decoration", value, important);
}

export function fontWeight(
  value?: ResponsiveProp<FontWeight>,
  important?: boolean
) {
  return css("font-weight", value, important);
}

export function fontSize(value?: ResponsiveProp<string>, important?: boolean) {
  return css("font-size", value, important);
}

export function lineHeight(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("line-height", value, important);
}

export function overflowY(
  value?: ResponsiveProp<Overflow>,
  important?: boolean
) {
  return css("overflow-y", value, important);
}

export function overflowX(
  value?: ResponsiveProp<Overflow>,
  important?: boolean
) {
  return css("overflow-x", value, important);
}

export function overflow(
  value?: ResponsiveProp<Overflow>,
  important?: boolean
) {
  return css("overflow", value, important);
}

export function whiteSpace(
  value?: ResponsiveProp<WhiteSpace>,
  important?: boolean
) {
  return css("white-space", value, important);
}

export function textOverflow(
  value?: ResponsiveProp<TextOverflow>,
  important?: boolean
) {
  return css("text-overflow", value, important);
}

export function textTransform(
  value?: ResponsiveProp<TextTransform>,
  important?: boolean
) {
  return css("text-transform", value, important);
}

export function letterSpacing(
  value?: ResponsiveProp<string>,
  important?: boolean
) {
  return css("letter-spacing", value, important);
}

// Read before adding new values https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
// Do you *need* another z index supported?
const ALLOWED_Z_INDEX_VALUES = [0, 1, 2];

export function zIndex(value?: number, important?: boolean) {
  if (
    process.env.NODE_ENV === "development" &&
    value !== undefined &&
    !ALLOWED_Z_INDEX_VALUES.includes(Number(value))
  ) {
    throw new Error(
      `Z index value ${value} is not supported - please use a value from ALLOWED_Z_INDEX_VALUES: ${ALLOWED_Z_INDEX_VALUES}`
    );
  }
  return css("z-index", value, important);
}

export function visibility(value?: string, important?: boolean) {
  return css("visibility", value, important);
}

export function opacity(value?: string, important?: boolean) {
  return css("opacity", value, important);
}
