import { makeBox } from "src/components/layout/Box";

import { ReactComponent as ArrowDown } from "./assets/Arrow.svg";
import { ReactComponent as ChevronDown } from "./assets/ChevronDown.svg";
import { ReactComponent as ChevronLeft } from "./assets/ChevronLeft.svg";
import { ReactComponent as ChevronRight } from "./assets/ChevronRight.svg";
import { ReactComponent as Clock } from "./assets/Clock.svg";
import { ReactComponent as Cross } from "./assets/Close.svg";
import { ReactComponent as Cog } from "./assets/Cog.svg";
import { ReactComponent as Copy } from "./assets/Copy.svg";
import { ReactComponent as DotsHorizontal } from "./assets/DotsHorizontal.svg";
import { ReactComponent as DotsVertical } from "./assets/DotsVertical.svg";
import { ReactComponent as Edit } from "./assets/Edit.svg";
import { ReactComponent as ExclamationCircle } from "./assets/ExclamationCircle.svg";
import { ReactComponent as Export } from "./assets/Export.svg";
import { ReactComponent as Globe } from "./assets/Globe.svg";
import { ReactComponent as Help } from "./assets/Help.svg";
import { ReactComponent as Leave } from "./assets/Leave.svg";
import { ReactComponent as Megaphone } from "./assets/Megaphone.svg";
import { ReactComponent as Dash } from "./assets/Minus.svg";
import { ReactComponent as PersonIcon } from "./assets/Person.svg";
import { ReactComponent as Plus } from "./assets/Plus.svg";
import { ReactComponent as Reassign } from "./assets/Reassign.svg";
import { ReactComponent as Search } from "./assets/Search.svg";
import { ReactComponent as Seneca } from "./assets/Seneca.svg";
import { ReactComponent as SenecaLoader } from "./assets/SenecaLoader.svg";
import { ReactComponent as Share } from "./assets/Share.svg";
import { ReactComponent as Star } from "./assets/Star.svg";
import { ReactComponent as Student } from "./assets/Student.svg";
import { ReactComponent as Tick } from "./assets/Tick.svg";
import { ReactComponent as TickCircle } from "./assets/TickCircle.svg";
import { ReactComponent as Transfer } from "./assets/Transfer.svg";
import { ReactComponent as Trash } from "./assets/Trash.svg";
import { ReactComponent as WhatsApp } from "./assets/WhatsApp.svg";

export const iconNameMap = Object.freeze({
  arrowDown: makeBox(ArrowDown),
  chevronDown: makeBox(ChevronDown),
  chevronLeft: makeBox(ChevronLeft),
  chevronRight: makeBox(ChevronRight),
  clock: makeBox(Clock),
  cog: makeBox(Cog),
  copy: makeBox(Copy),
  cross: makeBox(Cross),
  dash: makeBox(Dash),
  dotsHorizontal: makeBox(DotsHorizontal),
  dotsVertical: makeBox(DotsVertical),
  edit: makeBox(Edit),
  exclamationCircle: makeBox(ExclamationCircle),
  export: makeBox(Export),
  globe: makeBox(Globe),
  help: makeBox(Help),
  leave: makeBox(Leave),
  megaphone: makeBox(Megaphone),
  person: makeBox(PersonIcon),
  plus: makeBox(Plus),
  reassign: makeBox(Reassign),
  search: makeBox(Search),
  seneca: makeBox(Seneca),
  senecaLoader: makeBox(SenecaLoader),
  share: makeBox(Share),
  star: makeBox(Star),
  student: makeBox(Student),
  tick: makeBox(Tick),
  tickCircle: makeBox(TickCircle),
  transfer: makeBox(Transfer),
  trash: makeBox(Trash),
  whatsapp: makeBox(WhatsApp)
});

export type IconName = keyof typeof iconNameMap;

export const sizeMap = Object.freeze({
  xsmall: {
    height: "12px",
    width: "12px"
  },
  small: {
    height: "14px",
    width: "14px"
  },
  medium: {
    height: "16px",
    width: "16px"
  },
  large: {
    height: "20px",
    width: "20px"
  },
  fill: {
    height: "100%",
    width: "100%"
  }
});

export type Size = keyof typeof sizeMap;
