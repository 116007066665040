import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import type { Theme } from './types'

export const useTheme = (): Theme => {
  const theme = useContext(ThemeContext)

  if (!theme) {
    throw new Error('ThemeContextProvider required to access theme')
  }

  return theme
}
