import { Box, styledWithConfig } from "src/components/layout";
import { useDom } from "src/context/Dom";

import type { LocationShape } from "src/types";

type Args = {
  to: string | LocationShape;
  disabled?: boolean;
  newTab?: boolean;
  navLink?: boolean;
  styleProps: any
};

const AnchorComponent = styledWithConfig("a");

export function useGetLinkComponentAndProps({ 
  to, 
  disabled, 
  newTab,
  navLink,
  styleProps 
}: Args) {
  if (disabled) return { Component: Box };

  const external = isLinkExternal(to);

  if (external)
    return {
      Component: AnchorComponent,
      linkProps: {
        href: to,
        rel: "nofollow noopener noreferrer",
        target: newTab === false ? null : "_blank",
      },
    };
  
    const { Link, NavLink } = useDom();

  if (navLink) 
    return {
      Component: NavLink,
      linkProps: {
        to,
        target: newTab ? "_blank" : null,
        activeStyle: styleProps.activeNavLinkStyles
    }
  };

  return {
    Component: Link,
    linkProps: {
      to,
      target: newTab ? "_blank" : null,
    },
  };
}

export function isLinkExternal(link: string | LocationShape) {
  return (
    typeof link === "string" &&
    (link.startsWith("http") || link.startsWith("mailto:"))
  );
}
