export const levelMap = Object.freeze({
  '1': {
    component: 'h1',
    lineHeight: '140%',
    fontSize: '40px'
  },
  '2': {
    component: 'h2',
    lineHeight: '140%',
    fontSize: '32px'
  },
  '3': {
    component: 'h3',
    lineHeight: '160%',
    fontSize: '22px'
  },
  '4': {
    component: 'h4',
    lineHeight: '160%',
    fontSize: '20px'
  },
  '5': {
    component: 'h5',
    lineHeight: '160%',
    fontSize: '18px'
  }
})

export type Level = keyof typeof levelMap
