export const directionMap = Object.freeze({
  horizontal: {
    width: "100%",
    height: "1px",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderRightStyle: "none",
    borderRightWidth: 0,
  },
  vertical: {
    width: "1px",
    height: "100%",
    borderBottomStyle: "none",
    borderBottomWidth: 0,
    borderRightStyle: "solid",
    borderRightWidth: 1,
  },
});

export type Direction = keyof typeof directionMap;
