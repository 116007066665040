import React from "react";

import Box from "../Box";

type Props = {
  children: React.ReactNode;
};

const Center = React.forwardRef<Props, any>((props, ref) => {
  const { children } = props;
  return (
    <Box
      ref={ref}
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
      width="100%"
    >
      {children}
    </Box>
  );
});

export default Center;
