import { ResponsiveProp } from "./responsiveProps";

const DIMENSIONS = {
  none: 0,
  xxxsmall: 1,
  xxsmall: 2,
  xsmall: 3,
  small: 4,
  medium: 6,
  large: 8,
  xlarge: 16,
  xxlarge: 32,
  xxxlarge: 48,
  12: 12,
  24: 24
};

export const dimensionsArray: Array<string> = Object.keys(DIMENSIONS);

// TODO: update this to handle negative dimensions
export type Dimension = keyof typeof DIMENSIONS | "auto";

export type ResponsiveDimension = ResponsiveProp<Dimension>;

export const DEFAULT_DIMENSION: Dimension = "small";

export function validateDimension(dimension: Dimension) {
  // @ts-ignore
  if (!dimensionsArray.includes(dimension)) {
    throw new Error(`Invalid dimension '${dimension}' received.

Should be one of: 
\t * ${dimensionsArray.join("\n\t * ")}`);
  }
}

export function getDimension(dimension?: Dimension) {
  if (!dimension) {
    return undefined;
  }

  if (typeof dimension === "number") return dimension;

  // @ts-ignore
  const dimensionIsNegative = dimension.startsWith("-");

  // @ts-ignore
  const _dimension: Dimension = dimensionIsNegative
    ? // @ts-ignore
      dimension.slice(1)
    : dimension;

  validateDimension(_dimension);

  const value = DIMENSIONS[_dimension];
  return dimensionIsNegative ? -1 * value : value;
}

export function makeDimensionNegative(
  layoutDimension?: ResponsiveDimension
): ResponsiveDimension {
  if (!layoutDimension) {
    // @ts-ignore
    return layoutDimension;
  }

  if (typeof layoutDimension === "string") {
    // @ts-ignore
    return `-${layoutDimension}`;
  }

  // @ts-ignore
  return layoutDimension.map(dim => `-${dim}`);
}

export function getDimensionPx(dimension?: Dimension) {
  const _dim = getDimension(dimension);
  return isNaN(_dim) ? undefined : `${_dim}px`;
}

export function mapResponsiveDimensionsToPx(
  layoutDimension?: ResponsiveDimension
): ResponsiveProp<string> {
  if (!layoutDimension) {
    // @ts-ignore
    return undefined;
  }

  if (layoutDimension === "auto") return layoutDimension;

  if (
    typeof layoutDimension === "string" ||
    typeof layoutDimension === "number"
  ) {
    // @ts-ignore
    return getDimensionPx(layoutDimension);
  }

  // @ts-ignore
  return layoutDimension.map(getDimensionPx);
}

export function pxToRem(size: number) {
  return `${size / 16}rem`;
}
