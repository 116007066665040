import { UNSAFE_colorPalette } from "../colors/consts";

import { Theme } from "src/themes";
import colorWithOpacity from "src/utils/colorWithOpacity";

export const theme: Theme = {
  contrast: UNSAFE_colorPalette.white,
  primary: { ...UNSAFE_colorPalette.purple, 400: "#5f4d85" },
  warning: UNSAFE_colorPalette.red,
  success: UNSAFE_colorPalette.green,
  gray: UNSAFE_colorPalette.gray,

  UNSAFE_colorPalette,

  DEPRECATED: {
    primaryDarkOpacity40: colorWithOpacity("#120038", 0.4),
    primaryDarkOpacity80: colorWithOpacity("#120038", 0.8),
    primaryMediumOpacity20: colorWithOpacity(
      UNSAFE_colorPalette.purple["500"],
      0.2
    )
  }
};
