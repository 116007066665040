import React, { createContext, useContext, useMemo } from "react";

import { styledWithConfig } from "src/components/layout";

export type DomContext = {
  createPortal?: any;
  Link?: any;
  NavLink?: any;
};

const domContext = createContext<DomContext>({});

export const DomProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: DomContext;
}) => {
  const { Link, NavLink } = value;
  const StyledLink = useMemo(() => styledWithConfig(Link), [Link]);
  const StyledNavLink = useMemo(() => styledWithConfig(NavLink, {
    shouldForwardProp: (prop: string, defaultValidatorFn: (prop: string) => boolean) => {
      if (["activeStyle", "activeClassName", "exact"].includes(prop)) {
        return true;
      }
      return defaultValidatorFn(prop);
    }
  }), [NavLink])

  return (
    <domContext.Provider value={{
      ...value,
      Link: StyledLink,
      NavLink: StyledNavLink
    }}>
      {children}
    </domContext.Provider>
  );
};

export const useDom = () => useContext(domContext);
