import {
  mapResponsiveDimensionsToPx,
  ResponsiveDimension
} from "../dimensions";

import { responsiveToCss } from "./utils";

function css(
  propertyName: string,
  responsiveDimension?: ResponsiveDimension,
  important?: boolean
) {
  const responsiveDimensionPx = mapResponsiveDimensionsToPx(
    responsiveDimension
  );

  return responsiveToCss(propertyName, responsiveDimensionPx, important);
}

export function margin(value?: ResponsiveDimension, important?: boolean) {
  return css("margin", value, important);
}
export function marginTop(value?: ResponsiveDimension, important?: boolean) {
  return css("margin-top", value, important);
}

export function marginBottom(value?: ResponsiveDimension, important?: boolean) {
  return css("margin-bottom", value, important);
}
export function marginLeft(value?: ResponsiveDimension, important?: boolean) {
  return css("margin-left", value, important);
}
export function marginRight(value?: ResponsiveDimension, important?: boolean) {
  return css("margin-right", value, important);
}

export function marginX(value?: ResponsiveDimension, important?: boolean) {
  if (!value) return "";

  return marginRight(value, important) + marginLeft(value, important);
}

export function marginY(value?: ResponsiveDimension, important?: boolean) {
  if (!value) return "";

  return marginTop(value, important) + marginBottom(value, important);
}

export function padding(value?: ResponsiveDimension, important?: boolean) {
  return css("padding", value, important);
}
export function paddingTop(value?: ResponsiveDimension, important?: boolean) {
  return css("padding-top", value, important);
}
export function paddingBottom(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("padding-bottom", value, important);
}
export function paddingLeft(value?: ResponsiveDimension, important?: boolean) {
  return css("padding-left", value, important);
}
export function paddingRight(value?: ResponsiveDimension, important?: boolean) {
  return css("padding-right", value, important);
}

export function paddingX(value?: ResponsiveDimension, important?: boolean) {
  if (!value) return "";

  return paddingRight(value, important) + paddingLeft(value, important);
}

export function paddingY(value?: ResponsiveDimension, important?: boolean) {
  if (!value) return "";

  return paddingTop(value, important) + paddingBottom(value, important);
}

export function borderRadius(value?: ResponsiveDimension, important?: boolean) {
  return css("border-radius", value, important);
}

export function borderBottomLeftRadius(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-bottom-left-radius", value, important);
}

export function borderBottomRightRadius(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-bottom-right-radius", value, important);
}

export function borderTopLeftRadius(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-top-left-radius", value, important);
}

export function borderTopRightRadius(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-top-right-radius", value, important);
}

export function borderWidth(value?: ResponsiveDimension, important?: boolean) {
  return css("border-width", value, important);
}

export function borderRightWidth(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-right-width", value, important);
}

export function borderLeftWidth(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-left-width", value, important);
}

export function borderTopWidth(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-top-width", value, important);
}

export function borderBottomWidth(
  value?: ResponsiveDimension,
  important?: boolean
) {
  return css("border-bottom-width", value, important);
}

export function gridRowGap(value?: ResponsiveDimension, important?: boolean) {
  return css("grid-row-gap", value, important);
}

export function gap(value?: ResponsiveDimension, important?: boolean) {
  return css("gap", value, important);
}
