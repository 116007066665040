export type ResponsiveProp<Value> =
  | Value // all
  | [Value, Value] // [mobileOnly, tabletUp]
  | [Value, Value, Value] // [mobileOnly, tabletOnly, desktopUp]

export function normaliseResponsiveProp<T>(
  responsiveProp: ResponsiveProp<T>
): [T, T, T] {
  if (!Array.isArray(responsiveProp)) {
    return [responsiveProp, responsiveProp, responsiveProp]
  }

  if (responsiveProp.length === 2) {
    return [responsiveProp[0], responsiveProp[1], responsiveProp[1]]
  }

  if (responsiveProp.length === 3) {
    return responsiveProp
  }

  throw new Error('Invalid props argument')
}
