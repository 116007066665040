import styled from "styled-components";

import * as animationProp from "src/utils/css/animation";
import * as layoutProp from "src/utils/css/layout";
import * as cssProp from "src/utils/css/other";

import type { Styles } from "./types";

export const propsToStyles = (props: Styles): string => `

${layoutProp.margin(props.margin)}
${layoutProp.marginTop(props.marginTop)}
${layoutProp.marginBottom(props.marginBottom)}
${layoutProp.marginLeft(props.marginLeft)}
${layoutProp.marginRight(props.marginRight)}
${layoutProp.marginY(props.marginY)}
${layoutProp.marginX(props.marginX)}
${layoutProp.padding(props.padding)}
${layoutProp.paddingTop(props.paddingTop)}
${layoutProp.paddingBottom(props.paddingBottom)}
${layoutProp.paddingLeft(props.paddingLeft)}
${layoutProp.paddingRight(props.paddingRight)}
${layoutProp.paddingY(props.paddingY)}
${layoutProp.paddingX(props.paddingX)}
${layoutProp.borderRadius(props.borderRadius)}
${layoutProp.borderTopLeftRadius(props.borderTopLeftRadius)}
${layoutProp.borderTopRightRadius(props.borderTopRightRadius)}
${layoutProp.borderBottomLeftRadius(props.borderBottomLeftRadius)}
${layoutProp.borderBottomRightRadius(props.borderBottomRightRadius)}
${layoutProp.borderWidth(props.borderWidth)}
${layoutProp.borderBottomWidth(props.borderBottomWidth)}
${layoutProp.borderTopWidth(props.borderTopWidth)}
${layoutProp.borderLeftWidth(props.borderLeftWidth)}
${layoutProp.borderRightWidth(props.borderRightWidth)}

${cssProp.textAlign(props.textAlign)}
${cssProp.textDecoration(props.textDecoration)}
${cssProp.fontWeight(props.fontWeight)}
${cssProp.fontSize(props.fontSize)}
${cssProp.lineHeight(props.lineHeight)}
${cssProp.textOverflow(props.textOverflow)}
${cssProp.whiteSpace(props.whiteSpace)}
${cssProp.textTransform(props.textTransform)}
${cssProp.letterSpacing(props.letterSpacing)}

${cssProp.boxSizing(props.boxSizing || "border-box")}
${cssProp.display(props.display)}
${cssProp.justifyContent(props.justifyContent)}
${cssProp.justifySelf(props.justifySelf)}
${cssProp.justifyItems(props.justifyItems)}
${cssProp.alignItems(props.alignItems)}
${cssProp.alignSelf(props.alignSelf)}
${cssProp.flexDirection(props.flexDirection)}
${cssProp.flex(props.flex)}
${cssProp.flexWrap(props.flexWrap)}
${cssProp.position(props.position)}
${cssProp.top(props.top)}
${cssProp.bottom(props.bottom)}
${cssProp.left(props.left)}
${cssProp.right(props.right)}
${cssProp.gridTemplateAreas(props.gridTemplateAreas)}
${cssProp.gridTemplateColumns(props.gridTemplateColumns)}
${cssProp.gridArea(props.gridArea)}
${layoutProp.gap(props.gap)}
${layoutProp.gridRowGap(props.gridRowGap)}

${cssProp.border(props.border)}
${cssProp.borderColor(props.borderColor)}
${cssProp.borderStyle(props.borderStyle)}
${cssProp.borderRightStyle(props.borderRightStyle)}
${cssProp.borderBottomStyle(props.borderBottomStyle)}
${cssProp.boxShadow(props.boxShadow)}


${cssProp.width(props.width)}
${cssProp.maxWidth(props.maxWidth)}
${cssProp.minWidth(props.minWidth)}
${cssProp.height(props.height)}
${cssProp.maxHeight(props.maxHeight)}
${cssProp.minHeight(props.minHeight)}


${cssProp.cursor(props.cursor)}
${cssProp.pointerEvents(props.pointerEvents)}
${cssProp.overflowY(props.overflowY)}
${cssProp.overflowX(props.overflowX)}
${cssProp.overflow(props.overflow)}

${cssProp.backgroundColor(props.backgroundColor)}
${cssProp.background(props.background)}
${cssProp.color(props.color)}
${cssProp.fill(props.fill)}
${cssProp.stroke(props.stroke)}
${cssProp.outline(props.outline)}

${cssProp.zIndex(props.zIndex)}
${cssProp.visibility(props.visibility)}  
${cssProp.opacity(props.opacity)}  

${animationProp.transition(props.transition)}
${animationProp.transform(props.transform)}

${
  props.hoverStyles
    ? `:hover{
    ${propsToStyles(props.hoverStyles)}
  }`
    : ""
}

${
  props.activeStyles
    ? `:active{
    ${propsToStyles(props.activeStyles)}
  }`
    : ""
}

${
  props.disabledStyles
    ? `:disabled{
    ${propsToStyles(props.disabledStyles)}
  }`
    : ""
}

${
  props.focusStyles
    ? `:focus{
    ${propsToStyles(props.focusStyles)}
  }`
    : ""
}

${
  props.firstChildStyles
    ? `:first-child{
    ${propsToStyles(props.firstChildStyles)}
  }`
    : ""
}

${
  props.lastChildStyles
    ? `:last-child{
    ${propsToStyles(props.lastChildStyles)}
  }`
    : ""
}`;

const propKeysToBeFiltered = [
  "display",
  "width",
  "height",
  "top",
  "bottom",
  "left",
  "right",
  "cursor",
  "position"
];

const defaultShouldForwardProp = (
  prop: string,
  defaultValidatorFn: (arg0: string) => boolean
) => {
  if (propKeysToBeFiltered.includes(prop)) {
    return false;
  }
  return defaultValidatorFn(prop);
};

export const styledWithConfig = (
  componentType: string | any,
  config: any = {}
) =>
  styled(componentType).withConfig({
    ...config,
    shouldForwardProp: (
      prop: string,
      defaultValidatorFn: (prop: string) => boolean
    ) => {
      if (config.shouldForwardProp) {
        return config.shouldForwardProp(prop, (prop: string) =>
          defaultShouldForwardProp(prop, defaultValidatorFn)
        );
      }
      return defaultShouldForwardProp(prop, defaultValidatorFn);
    }
  })`
    ${propsToStyles}
  `;
