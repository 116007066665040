import React from "react";

import { useBackground } from "src/context/Background";
import { useTypography } from "src/context/Typography";
import { getColorFromTone } from "src/hooks/typography/useGetColorFromTone";
import { useTheme } from "src/themes";

import { iconNameMap, sizeMap } from "./consts";

import type { Tone as GenericTones } from "src/types";
import type { IconName, Size } from "./consts";
type Tone = GenericTones | "inherit";

type Props = {
  accessibilityLabel: string | null;
  iconName: IconName;
  size?: Size;
  tone?: Tone;
};

export default function Icon({
  accessibilityLabel,
  iconName,
  size,
  tone,
}: Props) {
  const color = useIconColor(tone);
  const { height, width } = useIconSize(size);

  const accessibilityProp = accessibilityLabel
    ? { "aria-label": accessibilityLabel }
    : { "aria-hidden": true };

  const Component = iconNameMap[iconName];

  return (
    <Component
      height={height}
      width={width}
      color={color}
      display="block"
      {...accessibilityProp}
    />
  );
}

function useIconColor(toneProp?: Tone) {
  const { tone: toneFromContext } = useTypography();
  const theme = useTheme();
  const background = useBackground();

  const tone = toneProp || toneFromContext || "default";

  if (tone === "inherit") {
    return;
  }

  return getColorFromTone({ theme, tone, background });
}

function useIconSize(sizeProp?: Size) {
  const { size: sizeFromContext } = useTypography();
  const size = sizeProp || sizeFromContext;

  if (!size) {
    throw new Error(
      "Size needs to either be passed as a prop or set on context"
    );
  }

  return sizeMap[size];
}
