import { UNSAFE_colorPalette } from "../colors/consts";

import { Theme } from "src/themes";
import colorWithOpacity from "src/utils/colorWithOpacity";

export const theme: Theme = {
  contrast: UNSAFE_colorPalette.white,
  primary: UNSAFE_colorPalette.blue,
  warning: UNSAFE_colorPalette.red,
  success: UNSAFE_colorPalette.green,
  gray: UNSAFE_colorPalette.gray,

  UNSAFE_colorPalette,

  DEPRECATED: {
    primaryDarkOpacity40: colorWithOpacity(
      UNSAFE_colorPalette.blue["500"],
      0.4
    ),
    primaryDarkOpacity80: colorWithOpacity(
      UNSAFE_colorPalette.blue["500"],
      0.8
    ),
    primaryMediumOpacity20: colorWithOpacity(
      UNSAFE_colorPalette.blue["400"],
      0.2
    )
  }
};
