import React from 'react'

import Box from 'src/components/layout/Box'
import { useIsOnStandardBackground } from 'src/context/Background'
import { TextProvider } from 'src/context/Text'
import { Theme, useTheme } from 'src/themes'
import colorWithOpacity from 'src/utils/colorWithOpacity'

import { uppercaseStyles } from '../consts'
import { sizeMap } from '../Text/consts'

import type { ThemedTagType } from './types'

const FONT_SIZE = sizeMap.xsmall.fontSize

type BaseExternalProps = {
  children: React.ReactNode
  uppercase?: boolean
  dataNoTranslate?: boolean
}

type ThemedProps = BaseExternalProps & {
  type: ThemedTagType
}

export default function Tag({ type, ...baseTagProps }: ThemedProps) {
  const isOnStandardBackground = useIsOnStandardBackground()
  const theme = useTheme()
  if (isOnStandardBackground) {
    throw new Error("Tags can't go on standard background")
  }

  const styles = getTagColours(theme, type)

  return <BaseTag {...baseTagProps} {...styles} />
}

export function getTagColours(theme: Theme, type: ThemedTagType) {
  if (type === 'strong') {
    return {
      background: theme.primary['500'],
      color: theme.contrast
    }
  }

  if (type === 'weak') {
    return {
      background: colorWithOpacity(theme.primary['500'], 0.2),
      color: theme.primary['700']
    }
  }

  return {
    background: colorWithOpacity(theme.gray['100'], 0.3),
    color: theme.gray['400']
  }
}

type BaseProps = {
  children: React.ReactNode
  uppercase?: boolean
  color: string
  background: string
  dataNoTranslate?: boolean
}

export function BaseTag({
  children,
  uppercase,
  color,
  background,
  dataNoTranslate
}: BaseProps) {
  return (
    <TextProvider value={true}>
      <Box
        paddingX='medium'
        paddingY='small'
        borderRadius='xxsmall'
        width='max-content'
        fontWeight='bold'
        fontSize={FONT_SIZE}
        color={color}
        backgroundColor={background}
        {...(uppercase && uppercaseStyles)}
        dataNoTranslate={dataNoTranslate}
      >
        {children}
      </Box>
    </TextProvider>
  )
}
