import React from "react";

import { Icon } from "src/components/content";
import { Box, Center, styledWithConfig } from "src/components/layout";
import { useGetButtonStyles } from "src/hooks/button";
import { ButtonFill, ButtonTone } from "src/hooks/button/types";
import useDelayOnPropChange from "src/hooks/useDelayOnPropChange";

import type { ResponsiveProp } from "src/types";

const ButtonComponent = styledWithConfig("button");

type Props = {
  children: React.ReactNode | string;
  onClick: () => void;
  accessibilityLabel?: string;
  disabled?: boolean;
  loading?: boolean;
  tone?: ButtonTone;
  fill?: ButtonFill;
  width?: ResponsiveProp<string | undefined>;
};

export default function Button({
  children,
  onClick,
  accessibilityLabel,
  tone = "primary",
  fill = "filled",
  disabled,
  loading,
  width = ["100%", undefined],
}: Props) {
  const styleProps = useGetButtonStyles({
    fill,
    tone,
    loading,
    disabled,
  });
  const delayedLoadingProp = useDelayOnPropChange(loading);

  return (
    <ButtonComponent
      display="block"
      height="48px"
      onClick={onClick}
      aria-label={accessibilityLabel}
      disabled={disabled || loading}
      // TODO: Update to 24px
      paddingX="xlarge"
      paddingY="none"
      borderRadius="xsmall"
      textDecoration="none"
      fontWeight="bold"
      cursor="pointer"
      {...styleProps}
      width={width}
    >
      <Center>
        <Box position="absolute">
          {delayedLoadingProp && (
            <Icon
              accessibilityLabel="Loading..."
              iconName="senecaLoader"
              tone="inherit"
              size="large"
            />
          )}
        </Box>

        <Box transform={delayedLoadingProp ? "scale(0)" : undefined}>
          {children}
        </Box>
      </Center>
    </ButtonComponent>
  );
}
