export const buttonFillMap = Object.freeze({
  filled: "filled",
  outlined: "outlined",
});

export type ButtonFill = keyof typeof buttonFillMap;

export const buttonToneMap = Object.freeze({
  primary: "primary",
  warning: "warning",
});

export type ButtonTone = keyof typeof buttonToneMap;
