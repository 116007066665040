// default argument stops getThemeType test from failing as css isn't imported in jest
export default function colorWithOpacity(
  hexColor: string = '',
  opacityDecimal: number
) {
  const removeHash = hexColor.replace('#', '')
  const rgb = removeHash.split(/(.{2})/g).filter(Boolean)

  const decimalChunks = rgb.map(hexToBase255)

  return `rgba(${decimalChunks.join(',')}, ${opacityDecimal})`
}

export function hexToBase255(hex: string) {
  return parseInt(hex, 16)
}
