import * as React from "react";

import { styledWithConfig } from "./utils";

import type { Styles } from "./types";

const components = {
  div: styledWithConfig("div"),
  h1: styledWithConfig("h1"),
  h2: styledWithConfig("h2"),
  h3: styledWithConfig("h3"),
  h4: styledWithConfig("h4"),
  h5: styledWithConfig("h5"),
  button: styledWithConfig("button"),
  p: styledWithConfig("p"),
  span: styledWithConfig("span"),
  label: styledWithConfig("label"),
  form: styledWithConfig("form"),
  input: styledWithConfig("input"),
};

export type Component = keyof typeof components;

type ComponentTypes =
  | { customComponent: true; component: React.ReactNode }
  | { customComponent?: false; component?: Component };

type OptionalA11yProps = {
  "aria-label"?: string;
  "aria-hidden"?: boolean;
  UNSAFE_ariaChecked?: boolean;
  UNSAFE_role?: string;
};

type MakeBoxProps = {
  className?: string;
  children?: React.ReactNode;
  dataNoTranslate?: boolean;
  dataTest?: string;
  onClick?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  for?: string;
} & Styles &
  OptionalA11yProps;

type Props = MakeBoxProps & ComponentTypes;

const Box = React.forwardRef<Props, any>((props, ref) => {
  const {
    component = "div",
    children,
    dataNoTranslate,
    dataTest,
    customComponent,
    UNSAFE_role,
    UNSAFE_ariaChecked,
    ...otherProps
  } = props;
  const Component = customComponent ? component : components[component];

  const dataAttributes = {
    "data-notranslate": dataNoTranslate,
    "data-test": dataTest,
  };

  const ariaAttributes = {
    role: UNSAFE_role,
    "aria-checked": UNSAFE_ariaChecked,
  };

  return (
    <Component
      ref={ref}
      {...otherProps}
      {...dataAttributes}
      {...ariaAttributes}
    >
      {children}
    </Component>
  );
});

export function makeBox(comp: React.ReactNode) {
  // @ts-ignore
  const component = styledWithConfig(comp);

  return function _Box(props: MakeBoxProps) {
    return <Box {...props} customComponent component={component} />;
  };
}

export default Box;
