import { createContext, useContext } from 'react'
import type { Tone } from 'types'

export type TypographyContext = {
  tone?: Tone
  size?: string
}

const typographyContext = createContext<TypographyContext>({})

export const TypographyProvider = typographyContext.Provider

export const useTypography = () => useContext(typographyContext)
