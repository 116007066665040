import React from "react";

import { Icon } from "src/components/content";
import { Center, styledWithConfig } from "src/components/layout";

import { IconButtonTone, IconName, Size, sizeMap } from "./consts";
import useGetIconButtonStyles from "./hooks/useGetIconButtonStyles";

const ButtonComponent = styledWithConfig("button");

type Props = {
  iconName: IconName;
  onClick: () => void;
  accessibilityLabel: string;
  disabled?: boolean;
  tone?: IconButtonTone;
  size?: Size;
};

const IconButton = React.forwardRef<Props, any>(
  (
    {
      iconName,
      onClick,
      accessibilityLabel,
      size = "large",
      tone = "primary",
      disabled,
    },
    ref
  ) => {
    const styleProps = useGetIconButtonStyles({
      tone,
      disabled,
    });

    const { height, width } = sizeMap[size];

    return (
      <ButtonComponent
        padding="none"
        height={height}
        width={width}
        onClick={onClick}
        aria-label={accessibilityLabel}
        disabled={disabled}
        ref={ref}
        {...styleProps}
      >
        <Center>
          <Icon
            accessibilityLabel="null"
            tone="inherit"
            iconName={iconName}
            size={size}
          />
        </Center>
      </ButtonComponent>
    );
  }
);

export default IconButton;
