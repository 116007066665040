import React from "react";

import { Box } from "src/components/layout";
import { useTheme } from "src/themes";

import { Size, sizeMap } from "./consts";
import { CustomCheckboxProps, makeCustomCheckbox } from "./CustomCheckbox";

type Props = CustomCheckboxProps & {
  size: Size;
};

const TRANSITION_DURATION = "0.2s";

export default makeCustomCheckbox(Toggle);

function Toggle({ checked, size, disabled }: Props) {
  const { height, width, borderWidth } = sizeMap[size];
  const { color } = useGetToggleStyles({ checked, disabled });

  return (
    <Box
      height={`${height}px`}
      width={`${width}px`}
      boxSizing="content-box"
      borderRadius="xlarge"
      border={`${borderWidth}px solid ${color}`}
      position="relative"
      transition={TRANSITION_DURATION}
    >
      <Box
        height={`${height - borderWidth * 2}px`}
        width={`${height - borderWidth * 2}px`}
        top={`${borderWidth}px`}
        left={`${borderWidth}px`}
        position="absolute"
        borderRadius="xlarge"
        backgroundColor={color}
        transition={TRANSITION_DURATION}
        transform={
          checked && !disabled ? `translateX(${width - height}px)` : "none"
        }
      />
    </Box>
  );
}

function useGetToggleStyles({
  checked,
  disabled
}: {
  checked: boolean;
  disabled?: boolean;
}) {
  const { gray, success } = useTheme();

  if (disabled) {
    return {
      color: gray[200]
    };
  }

  if (checked) {
    return {
      color: success[200]
    };
  }

  return {
    color: gray[100]
  };
}
