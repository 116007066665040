import React, { createContext, useContext } from "react";

export type BackgroundVariant = "standard" | "inverted";

const backgroundContext = createContext<BackgroundVariant>("standard");

export const BackgroundProvider = backgroundContext.Provider;

export const useBackground = () => useContext(backgroundContext);

export const useIsOnStandardBackground = () => useBackground() === "standard";

export function getIsOnStandardBackground(background: BackgroundVariant) {
  return background === "standard";
}

type Props = { children: React.ReactNode };

export const BackgroundInverter = ({ children }: Props) => {
  const isOnStandardBackground = useIsOnStandardBackground();
  const background: BackgroundVariant = isOnStandardBackground
    ? "inverted"
    : "standard";

  return (
    <backgroundContext.Provider value={background}>
      {children}
    </backgroundContext.Provider>
  );
};

export const BackgroundInvertedProvider = ({ children }: Props) => {
  return (
    <backgroundContext.Provider value="inverted">
      {children}
    </backgroundContext.Provider>
  );
};

export const BackgroundStandardProvider = ({ children }: Props) => {
  return (
    <backgroundContext.Provider value="standard">
      {children}
    </backgroundContext.Provider>
  );
};
