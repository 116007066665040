import type { Tone } from 'types'
import {
  BackgroundVariant,
  getIsOnStandardBackground,
  useBackground
} from 'src/context/Background'
import { useTypography } from 'src/context/Typography'
import { Theme, useTheme } from 'src/themes'

export default function useGetColorFromTone(
  toneProp?: Tone,
  backgroundOverride?: BackgroundVariant
) {
  const theme = useTheme()
  const backgroundFromContext = useBackground()
  const { tone: toneFromContext } = useTypography()

  const background = backgroundOverride || backgroundFromContext
  if (!theme) {
    throw new Error("Can't get color without theme context")
  }
  if (!background) {
    throw new Error("Can't get color without background context")
  }

  const tone: Tone = toneProp || toneFromContext || 'default'

  return getColorFromTone({ theme, tone, background })
}

export function getColorFromTone({
  theme,
  tone,
  background
}: {
  theme: Theme
  tone: Tone
  background: BackgroundVariant
}) {
  if (getIsOnStandardBackground(background)) {
    return theme.contrast
  }

  if (tone === 'default') {
    return theme.gray['600']
  }

  if (tone === 'neutral') {
    return theme.gray['500']
  }

  return theme[tone]['500']
}
