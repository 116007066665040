export type { IconName } from "../../content/Icon/consts";
export { iconNameMap } from "../../content/Icon/consts";

export const sizeMap = Object.freeze({
  small: {
    height: "32px",
    width: "32px",
  },
  medium: {
    height: "40px",
    width: "40px",
  },
  large: {
    height: "48px",
    width: "48px",
  },
});

export type Size = keyof typeof sizeMap;

export const iconButtonToneMap = Object.freeze({
  primary: "primary",
  warning: "warning",
  blend: "blend",
});

export type IconButtonTone = keyof typeof iconButtonToneMap;
