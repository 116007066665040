import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import type { Theme } from "./types";

type Props = { theme: Theme; children: React.ReactNode };

export default function ThemeProvider({ theme, children }: Props) {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}
