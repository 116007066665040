export const sizeMap = Object.freeze({
  xxsmall: {
    fontSize: '11px',
    lineHeight: '140%'
  },
  xsmall: {
    fontSize: '12px',
    lineHeight: '160%'
  },
  small: {
    fontSize: '14px',
    lineHeight: '160%'
  },
  medium: {
    fontSize: '16px',
    lineHeight: '160%'
  }
})

export type Size = keyof typeof sizeMap

export const truncateStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}
