import React from "react";

import { Box, Inline, Stack } from "src/components/layout";
import { BackgroundInvertedProvider } from "src/context/Background";
import { useDom } from "src/context/Dom";
import { LocationShape } from "src/types";

import { Position } from "../../types";

type MenuProps = {
  children: React.ReactNode;
  position?: Position;
};

export const ContextMenu = React.forwardRef<MenuProps, any>(
  (props: MenuProps, ref) => {
    const { children, position } = props;
    const styles = getMenuStyles(position);
    return (
      <BackgroundInvertedProvider>
        <Box
          zIndex={2}
          backgroundColor="white"
          boxShadow="0px 6px 20px #3333331a"
          overflow="hidden"
          {...styles}
        >
          <Stack ref={ref}>{children}</Stack>
        </Box>
      </BackgroundInvertedProvider>
    );
  }
);

export default ContextMenu;

const getMenuStyles = (position?: string) => {
  if (position === "bottomLeft") {
    return {
      borderRadius: "xsmall",
      borderTopRightRadius: "none",
    };
  }

  if (position === "bottomRight") {
    return {
      borderRadius: "xsmall",
      borderTopLeftRadius: "none",
    };
  }

  return {
    borderRadius: "xsmall",
  };
};

export type ButtonChildren =
  | React.ReactNode
  | {
      icon: React.ReactNode;
      text: React.ReactNode;
    };

export type MenuButtonProps = {
  children: ButtonChildren;
  ariaLabel?: string;
  onClick: () => void;
  disabled?: boolean;
};

export function ContextMenuButton(props: MenuButtonProps) {
  const { children, ariaLabel, onClick, disabled } = props;
  const styles = getContextButtonStyles();

  return (
    <Box
      component="button"
      className="focus-malibu"
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      {...styles}
    >
      <ButtonContents disabled={disabled}>{children}</ButtonContents>
    </Box>
  );
}

export type MenuLinkProps = {
  children: ButtonChildren;
  onClick?: () => void;
  ariaLabel?: string;
  to: string | LocationShape;
};

export function ContextMenuLink({
  children,
  ariaLabel,
  to,
  onClick,
}: MenuLinkProps) {
  const { Link } = useDom();
  const styles = getContextButtonStyles();

  return (
    <Link
      {...styles}
      className="focus-malibu"
      aria-label={ariaLabel}
      onClick={onClick}
      //@ts-ignore
      to={to}
    >
      <ButtonContents>{children}</ButtonContents>
    </Link>
  );
}

export type ButtonContentsProps = {
  children: ButtonChildren;
  disabled?: boolean;
};

export function ButtonContents({ children, disabled }: ButtonContentsProps) {
  const onClick = (e: any) => disabled && e.stopPropagation();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      minWidth="150px"
      paddingLeft="medium"
      paddingRight="medium"
      onClick={onClick}
    >
      {templated(children) ? (
        <Inline space="large" alignItems="center">
          {/* @ts-ignore */}
          {children.icon}
          {/* @ts-ignore */}
          {children.text}
        </Inline>
      ) : (
        children
      )}
    </Box>
  );
}

function templated(children: ButtonChildren) {
  return (
    //@ts-ignore
    children && typeof children === "object" && children.icon && children.text
  );
}

function getContextButtonStyles() {
  return {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "100%",
    padding: "small",
    background: "none",
    outline: "none",
    border: "none",
    textDecoration: "none",
    cursor: "pointer",
    color: "unset",
    borderRadius: "small",
    boxSizing: "border-box",
    backgroundColor: "white",
    hoverStyles: {
      cursor: "pointer",
      backgroundColor: "#e1e3e566",
    },
    disabledStyles: {
      cursor: "default",
      color: "#ceced0",
    },
    activeStyles: {
      backgroundColor: "#e1e3e5",
    },
  };
}
