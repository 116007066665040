import React, { ComponentType } from "react";

import { Box, styledWithConfig } from "src/components/layout";

export type CustomCheckboxProps = {
  name: string;
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
};

const Input = styledWithConfig("input");

export function makeCustomCheckbox<PropsT extends CustomCheckboxProps>(
  Component: ComponentType<PropsT>
) {
  return function _CustomCheckbox(props: PropsT) {
    const { name, checked, onChange, disabled } = props;

    return (
      <Box
        component="label"
        for={name}
        cursor={disabled ? "not-allowed" : "pointer"}
        display="block"
        width="fit-content"
        height="fit-content"
      >
        <Input
          id={name}
          name={name}
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          width="1px"
          height="1px"
          position="absolute"
          top="auto"
          left="-10000px"
        />
        <Box
          width="1px"
          height="1px"
          position="absolute"
          top="auto"
          left="-10000px"
        >
          {name}
        </Box>
        <Component {...props} />
      </Box>
    );
  };
}
