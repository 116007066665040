const colors = Object.freeze({
  blueGem: "#4917b4",
  bridesmaid: "#feeced",
  carnation: "#f5454c",
  cornflowerBlue: "#694fef",
  dustyGray: "#969696",
  flushMahogany: "#c2373c",
  frenchGray: "#ceced0",
  iron: "#e2e4e6",
  mariner: "#3574cf",
  mineShaft: "#333333",
  persianIndigo: "#371187",
  premiumLight: "#f9f8ff",
  purpleHeart: "#5b1de1",
  royalBlue: "#4086ea",
  scorpion: "#595959",
  silverChalice: "#ababab",
  stTropaz: "#26508c",
  valencia: "#db3e44",
  white: "#ffffff",
  jungleGreen: "#25b999",
  jungleGreenLight: "#2dd1ac"
});

export const UNSAFE_colorPalette = {
  white: colors.white,
  blue: {
    "100": "#f1f9ff",
    "200": "#d9e7fb",
    "400": colors.mariner,
    "500": colors.royalBlue,
    "600": "#336BBB",
    "700": colors.stTropaz
  },
  purple: {
    "100": "#f9f8ff",
    "200": "#ded2f9",
    "400": colors.cornflowerBlue,
    "500": colors.purpleHeart,
    "600": colors.blueGem,
    "700": colors.persianIndigo
  },
  red: {
    "100": colors.bridesmaid,
    "500": colors.carnation,
    "600": colors.valencia,
    "700": colors.flushMahogany
  },
  gray: {
    "100": colors.iron,
    "200": colors.frenchGray,
    "300": colors.silverChalice,
    "400": colors.dustyGray,
    "500": colors.scorpion,
    "600": colors.mineShaft
  },
  green: {
    "100": colors.jungleGreenLight,
    "200": colors.jungleGreen
  }
};
