import designTokens from "src/designTokens";

const {
  breakpoints: { mobile, tablet }
} = designTokens;

export const maxPhoneWidth = mobile;
export const maxTabletWidth = tablet;

export function forPhoneOnly(
  cssValue?: string,
  phoneWidth: number = maxPhoneWidth
) {
  if (!cssValue) return "";

  return `@media only screen and (max-width: ${phoneWidth}px) {
      ${cssValue}
    }`;
}

export function forTabletUp(cssValue?: string, phoneWidth = maxPhoneWidth) {
  if (!cssValue) return "";

  return `@media only screen and (min-width: ${phoneWidth}px) {
      ${cssValue}
    }`;
}

export function forTabletOnly(
  cssValue?: string,
  phoneWidth = maxPhoneWidth,
  desktopWidth = maxTabletWidth
) {
  if (!cssValue) return "";

  return `@media only screen and (min-width: ${phoneWidth}px) and (max-width: ${desktopWidth}px) {
      ${cssValue}
    }`;
}

export function forDesktopUp(cssValue?: string, desktopWidth = maxTabletWidth) {
  if (!cssValue) return "";

  return `@media only screen and (min-width: ${desktopWidth}px) {
      ${cssValue}
    }`;
}
