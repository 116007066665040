import { Theme } from "src/themes";

import { theme as freeTheme } from "./freeTheme";
import { theme as premiumTheme } from "./premiumTheme";
import { theme as smartLearningTheme } from "./smartLearningTheme";

const themes = { premiumTheme, smartLearningTheme, freeTheme };
export { themes };

export type ThemeProps = { theme: Theme };
