import React, { createContext, useMemo } from 'react'

type Dimensions = {
  width: number
  height: number
}

type Props = Dimensions & { children: React.ReactNode }

export const DimensionsContext = createContext<Dimensions>({
  width: window.innerWidth,
  height: window.innerHeight
})

export function DimensionsProvider({ width, height, children }: Props) {
  const value = useMemo(() => ({ width, height }), [width, height])
  return (
    <DimensionsContext.Provider value={value}>
      {children}
    </DimensionsContext.Provider>
  )
}
