import {
  BackgroundVariant,
  getIsOnStandardBackground,
  useBackground,
} from "src/context/Background";
import { Theme, useTheme } from "src/themes";

import { ButtonFill, buttonFillMap, ButtonTone } from "./types";

type Args = {
  fill: ButtonFill;
  tone: ButtonTone;
  loading?: boolean;
  disabled?: boolean;
};

export const filledProps = {
  borderWidth: "none",
};

const disabledProps = {
  cursor: "not-allowed",
};

const loadingProps = { cursor: "progress" };

const outlinedProps = {
  borderWidth: "xxsmall",
  borderStyle: "solid",
  backgroundColor: "transparent",
};

export function useGetButtonStyles({ fill, tone, loading, disabled }: Args) {
  const theme = useTheme();
  const background = useBackground();

  if (fill === buttonFillMap.outlined) {
    return getOutlinedButtonProps({
      theme,
      background,
      loading,
      tone,
      disabled,
    });
  }

  return getFilledButtonProps({ theme, background, loading, tone, disabled });
}

type GetPropsArgs = {
  theme: Theme;
  background: BackgroundVariant;
  tone: ButtonTone;
  disabled?: boolean;
  loading?: boolean;
};
function getOutlinedButtonProps({
  theme,
  background,
  tone,
  disabled,
  loading,
}: GetPropsArgs) {
  const variants = theme[tone];
  const { contrast } = theme;

  if (getIsOnStandardBackground(background)) {
    if (disabled) {
      const { gray } = theme;
      return {
        ...outlinedProps,
        ...disabledProps,
        color: gray["500"],
        backgroundColor: gray["100"],
        borderColor: gray["100"],
      };
    }

    return {
      ...outlinedProps,
      ...(loading && loadingProps),
      borderColor: contrast,
      color: contrast,
      hoverStyles: {
        backgroundColor: contrast,
        borderColor: contrast,
        color: variants["600"],
      },
      activeStyles: {
        backgroundColor: contrast,
        borderColor: contrast,
        color: variants["700"],
      },
    };
  }

  if (disabled) {
    const { gray } = theme;
    return {
      ...outlinedProps,
      ...disabledProps,
      color: gray["500"],
      borderColor: gray["500"],
    };
  }
  return {
    ...outlinedProps,
    ...(loading && loadingProps),
    color: variants["500"],
    borderColor: variants["500"],
    hoverStyles: {
      color: variants["600"],
      borderColor: variants["600"],
    },
    activeStyles: {
      color: variants["700"],
      borderColor: variants["700"],
    },
  };
}

export function getFilledButtonProps({
  theme,
  background,
  tone,
  disabled,
  loading,
}: GetPropsArgs) {
  const variant = theme[tone];
  const { contrast } = theme;

  if (disabled) {
    return {
      ...filledProps,
      ...disabledProps,
      color: theme.gray["500"],
      backgroundColor: theme.gray["100"],
    };
  }

  if (getIsOnStandardBackground(background)) {
    return {
      ...filledProps,
      ...(loading && loadingProps),
      color: variant["500"],
      backgroundColor: contrast,
      hoverStyles: {
        color: variant["600"],
        backgroundColor: contrast,
      },
      activeStyles: {
        color: variant["700"],
        backgroundColor: contrast,
      },
    };
  }

  return {
    ...filledProps,
    ...(loading && loadingProps),
    backgroundColor: variant["500"],
    color: contrast,
    hoverStyles: {
      backgroundColor: variant["600"],
      color: contrast,
    },
    activeStyles: {
      backgroundColor: variant["700"],
      color: contrast,
    },
  };
}
